import { APP_BANNER_CLOSED, APP_VAULTS_FYI_URL } from '@/constants'
import { getLocalStorageValue, setLocalStorageValue, trackEvent } from '@/utils'
import { cn } from '@/utils/cn'
import { useEffect, useState } from 'react'
import appBannerImage from '/images/appBanner.jpg'
import { Button, type ButtonProps } from '../atoms/Button'
import { ArrowCircleIcon, CrossIcon } from '../atoms/Icons'
import { Panel } from '../atoms/Panel'

export const AppBanner = () => {
  const [isClosed, setClosed] = useState<boolean>(Boolean(getLocalStorageValue(APP_BANNER_CLOSED)))

  const handleClose = () => {
    setLocalStorageValue(APP_BANNER_CLOSED, true)
    setClosed(true)
    trackEvent({ event: 'App banner closed' })
  }

  useEffect(() => {
    setLocalStorageValue(APP_BANNER_CLOSED, isClosed)
  }, [isClosed])

  return (
    <Panel className={cn(isClosed ? 'hidden' : 'flex', 'relative mb-6 flex-col-reverse p-0 md:flex-row')}>
      <div className="relative h-32 w-full md:h-auto md:max-w-xl">
        <img
          src={appBannerImage}
          alt="App banner"
          aria-hidden="true"
          className="absolute inset-0 h-full w-full object-cover object-top md:object-right lg:h-auto"
        />
      </div>
      <div className="flex w-full flex-col gap-3 px-4 pt-6 pb-3 md:max-w-md md:gap-4 md:p-6 lg:max-w-full lg:px-12">
        <h4 className="mr-6 font-bold text-lg sm:text-xl md:text-2xl">Put your funds to work with vaults.fyi</h4>
        <div className="inline gap-4 md:flex md:flex-col">
          <p className="inline text-text-secondary md:block">
            Discover yields and deploy funds across 50+ vaults, all in one app. Zero intermediaries, fully
            non-custodial.
          </p>
          <LinkButton variant="text" className="ml-2 inline-flex md:hidden" />
          <LinkButton className="hidden w-fit min-w-36 md:inline-flex" />
        </div>
      </div>
      <Button
        variant="default"
        className="absolute top-4 right-4 text-text-secondary opacity-50 hover:opacity-100 focus-visible:opacity-100"
        onClick={handleClose}
      >
        <CrossIcon size={24} />
      </Button>
    </Panel>
  )
}

const LinkButton = ({ className, ...props }: ButtonProps) => {
  return (
    <Button
      className={cn('items-center underline md:no-underline', className)}
      {...props}
      asChild
      onClick={() => trackEvent({ event: 'App redirect', params: { Source: 'Banner' } })}
    >
      <a href={APP_VAULTS_FYI_URL} target="_blank" rel="noreferrer">
        Go to app
        <ArrowCircleIcon
          direction="right"
          className="min-h-4 min-w-4 translate-y-0.5 md:min-h-6 md:min-w-6 md:translate-y-0"
        />
      </a>
    </Button>
  )
}
